// Set file variable
$filename: "scss/custom/_banner.scss";

/*==============================================================================
  @Banner - #{$filename}
==============================================================================*/

/**
 * Structure
 */

.banner {
  position: relative;
  overflow: hidden;
  background: $bg-banner;
  background: var(--bg-banner, var(--bg-header, $bg-banner));

  .container {
    position: relative;
    padding-bottom: 1em;
  }
}

.banner-full {
  background: $bg-banner-full;
  background: var(--bg-banner-full, var(--bg-header, $bg-banner-full));
}

.banner-full::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $bg-banner-full;
  background: var(--bg-banner-full, var(--bg-header, $bg-banner-full));
  opacity: 0.6;
  z-index: 5;
}

.banner-full .container {
  position: static;
  z-index: 10;
}

@include media-min("medium") {
  .banner .container {
    padding-top: 2em;
    padding-bottom: 2em;
    min-height: 450px;
  }
  .banner-full .container {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}

/**
 * Banner Content
 */

.banner-content {
  position: relative;
  z-index: 5;
  margin: 0 auto;
  text-align: center;

  h1 {
    margin-top: 0;
    font-weight: font-weight("light");
  }

  .text-lead {
    font-size: px-to-em(16px);
    font-weight: font-weight("regular");
  }
}

@include media-min("medium") {
  .banner-content {
    max-width: 50%;
    float: right;
    text-align: left;

    h1 {
      margin-top: 1rem;
      font-size: 3em;
    }
  }

  .banner-full .banner-content {
    max-width: 700px;
    float: none;
    text-align: center;
  }
}

/**
 * Banner Actions
 */

.banner-action {
  margin: 1em 0;

  .button,
  .phone {
    display: block;
    margin: 0 0 1em 0;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .phone {
    font-size: 0.9rem;
    text-transform: uppercase;
    color: $color-light;

    .number {
      display: block;
      font-size: 1.2rem;
      color: $color;
    }
  }
}

@include media-min("medium") {
  .banner-action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 2em 0;

    .button,
    .phone {
      display: inline-block;
      margin: 0 1em 0 0;
      text-align: left;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .banner-full .banner-action {
    justify-content: center;
    margin: 2em 0 3em;
  }
}

/* Text Light */
.banner.text-light {
  color: $white;

  h1,
  .text-lead {
    color: $white;
  }

  .banner-action .phone {
    color: rgba($white, 0.75);
  }

  .banner-action .phone .number a {
    color: $white;
  }
}

/**
 * Banner Images
 */

.banner-images {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  max-width: 480px;

  img {
    margin: 0;
  }
}

.banner-full .banner-images {
  width: 100%;
  height: 100%;
  max-width: none;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include media-min("medium") {
  .banner-images {
    display: block;
  }
}
