// Set file variable
$filename: 'scss/custom/_header-action.scss';

/*==============================================================================
  @Action - #{$filename}
==============================================================================*/

.action-menu {
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 0 -1.5em 1.5em;
  padding: 0.25em;
  background: $bg-nav;
  background: var(--bg-nav, $bg-nav);
  border-bottom: 1px solid rgba($white, 0.1);

  .button {
    flex: 1 0 auto;
    margin: 0 1px 0 0;
    padding: 0.75em 1em;
    background: none;
    color: $white;
    @include add-icon-button('left');

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      background: none;
      color: $accent;
      color: var(--accent, $accent);
    }
  }

  .button-menu {
    flex: 0 0 auto;
  }

  .button.text {
    padding: 0;
    margin-right: 1em;
    border: none;
    font-size: 1.2em;
    color: $accent;
    color: var(--accent, $accent);

    &:hover {
      color: $accent;
      color: var(--accent, $accent);
    }
  }

  a[x-apple-data-detectors] {
    display: none;
  }
}

@include media-min('medium') {
  .action-menu {
    overflow: visible;
    margin: 0;
    padding: 0;
    background: none;
    border-bottom: none;

    .button {
      margin-right: 10px;
      padding: 0.75em 1.5em;
      color: $accent;
      color: var(--accent, $accent);
      border-width: 1px;
      border-style: solid;
      border-color: $accent;
      border-color: var(--accent, $accent);

      &:hover,
      &:focus {
        border-color: $white;
        color: $white;
      }
    }
  }
}
