// Set file variable
$filename: 'scss/custom/_buttons.scss';

/*==============================================================================
  @Buttons - #{$filename}
==============================================================================*/

.button {
  @include make-button();
  @include add-button-color((
    'background-deg' : 2%,
  ));
  // There are cases where we want button text to wrap
  white-space: normal;

  &.block {
    display: block;
  }
}

.button.default {
  @include add-button-color((
    'background' : rgba($black, 0.05),
    'hover' : (
      'background' : rgba($black, 0.1)
    )
  ));
}

.button.primary {
  background: $color-button;

  &:hover {
    background: $color-button-hover;
  }

  &:active {
    background: $color-button-hover;
  }

  @include add-button-color((
    'color' : $white,
    'background' : var(--color-button, var(--accent, $color-button)),

    'hover' : (
      'background' : var(--color-button-hover, var(--accent-hover, $color-button-hover)),
    ),

    'active' : (
      'background' : var(--color-button-hover, var(--accent-hover, $color-button-hover)),
    ),
  ));
}

.button.secondary {
  background: $secondary;

  &:hover {
    background: $secondary-hover;
  }

  &:active {
    background: $secondary-hover;
  }

  @include add-button-color((
    'color' : $white,
    'background' : var(--color-button, var(--secondary, $secondary)),

    'hover' : (
      'background' : var(--color-button-hover, var(--secondary-hover, $secondary-hover)),
    ),

    'active' : (
      'background' : var(--color-button-hover, var(--secondary-hover, $secondary-hover)),
    ),
  ));
}

.button.outline {
  &:hover {
    background: $color-button;
  }

  &:active {
    background: $color-button;
  }

  @include add-button-color((
    'background' : transparent,
    'border' : 1px solid rgba($black, 0.1),

    'hover' : (
      'background' : transparent,
      'border-color' : var(--color-button, var(--accent, $color-button)),
    ),

    'active' : (
      'background' : transparent,
      'border-color' : var(--color-button, var(--accent, $color-button)),
    ),
  ));
}
