// Set file variable
$filename: 'scss/custom/_header.scss';

/*==============================================================================
  @Header - #{$filename}
==============================================================================*/

.header {
  background: $bg-header;
  background: var(--bg-header, $bg-header);
  padding: 0;
  text-align: center;
  overflow: auto;
}

@include media-min('medium') {
  .header {
    padding: 1.5em 0;

    .container {
      @include remove-clearfix();
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
    }
  }
}
